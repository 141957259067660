/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  margin-left: 12px;
}
div.sub1 .menu {
  color: #9d9c9c;
  font-size: 16px;
  text-transform: lowercase;
  padding: 0 12px;
  background: url(/images/line-gray.png) no-repeat 0 50%;
}
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  color: #fff;
}
div.sub1 a.path {
  color: #fff;
}
/* LEVEL 2 */
div.sub2 {
  width: 100%;
  margin-top: 7px;
}
.sub2 > .item {
  width: 100%;
  margin: 7px 0;
}
div.sub2 .menu {
  float: left;
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  color: #fff;
  padding-right: 12px;
  text-transform: lowercase;
  font-weight: normal;
  font-family: 'CenturyGothicBold', helvetica, sans-serif;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  color: #9d9c9c;
}
div.sub2 a.path {
  color: #fff;
}
/* LEVEL 3 */
.sub3 > .item.init {
  display: none;
}
div.sub3 .menu {
  margin-left: 0;
  padding: 0 12px;
  font-family: 'CenturyGothicRegular', helvetica, sans-serif;
  color: #9d9c9c;
  background: url(/images/line-gray.png) no-repeat 0 50%;
}
div.sub3 .menu .edit {
  background: none !important;
}
div.sub3 .menu:hover,
div.sub3 .menu:focus {
  color: #fff;
}
div.sub3 a.path {
  color: #fff;
}
/* LEVEL 4 */
div.sub4 {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed #9d9c9c;
  display: none;
}
.sub4 > .item {
  margin-left: 15px;
}
div.sub4 .menu {
  color: #9d9c9c;
  font-family: 'CenturyGothicRegular', helvetica, sans-serif;
}
div.sub4 .menu:hover,
div.sub4 .menu:focus {
  color: #fff;
}
div.sub4 a.path {
  color: #fff;
}
#services {
  float: right;
  margin: 16px 0 56px 0;
}
#services .meta {
  float: left;
  font-size: 10px;
  margin-right: 15px;
}
#services .meta.firm,
#services .meta.auth {
  display: none;
}
.cb-page-last #services .meta.firm,
.cb-page-last #services .meta.auth {
  display: inline;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 96%;
}
.l3home {
  margin-left: 0;
  margin-right: 0.58333333%;
  float: right;
}
.menupfad,
.navigation .desk {
  display: block;
}
.copyright {
  margin-left: 1.25%;
}
.footer {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
.navigation {
  height: auto;
}
.layout3 .hometoggle {
  display: block;
}
h1 {
  margin: 0 1.25%;
}
#over {
  background: rgba(0, 0, 0, 0.7);
}
#disp .head .cb-hybrid,
#disp a.quit {
  background: url(/icon-lightbox/lb-ctr.png) no-repeat 0 0;
  width: 12px;
  height: 20px;
}
.meta.tell {
  right: 1.25%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0.99009901%;
  margin-left: 0.99009901%;
}
.area .part,
.area > .grid table {
  margin-right: 0.99009901%;
  margin-left: 0.99009901%;
  width: 98.01980198%;
}
.area .tiny {
  width: 48.01980198%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 1.98019802%;
  margin-left: 1.98019802%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96.03960396%;
}
.area > .slim .tiny {
  width: 96.03960396%;
}
.cb-page-layout2 .main {
  width: 100%;
}
.cb-page-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .main h2,
.cb-page-layout2 .main .foot {
  margin-right: 1.98019802%;
  margin-left: 1.98019802%;
}
.cb-page-layout2 .main .part,
.cb-page-layout2 .main > .grid table {
  margin-right: 1.98019802%;
  margin-left: 1.98019802%;
  width: 96.03960396%;
}
.cb-page-layout2 .main > .slim .part,
.cb-page-layout2 .main > .slim.grid table {
  width: 96.03960396%;
}
.cb-page-layout2 .side {
  width: 100%;
}
.cb-page-layout2 .side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .side h2,
.cb-page-layout2 .side .foot {
  margin-right: 0.99009901%;
  margin-left: 0.99009901%;
}
.cb-page-layout2 .side .part,
.cb-page-layout2 .side > .grid table {
  margin-right: 0.99009901%;
  margin-left: 0.99009901%;
  width: 98.01980198%;
}
.cb-page-layout2 .side .tiny {
  width: 48.01980198%;
}
.cb-page-layout2 .side > .slim {
  width: 50%;
}
.cb-page-layout2 .side > .slim h2,
.cb-page-layout2 .side > .slim .foot,
.cb-page-layout2 .side > .slim .part,
.cb-page-layout2 .side > .slim.grid table {
  margin-right: 1.98019802%;
  margin-left: 1.98019802%;
}
.cb-page-layout2 .side > .slim .part,
.cb-page-layout2 .side > .slim.grid table {
  width: 96.03960396%;
}
.cb-page-layout2 .side > .slim .tiny {
  width: 96.03960396%;
}
.cb-page-layout4 .main {
  width: 100%;
}
.cb-page-layout4 .main > .unit {
  margin-right: 0.4950495%;
  margin-left: 0.4950495%;
  width: 99.00990099%;
}
.cb-page-layout4 .main h2,
.cb-page-layout4 .main .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.cb-page-layout4 .main .part,
.cb-page-layout4 .main > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.cb-page-layout4 .main > .slim {
  width: 24.00990099%;
}
.cb-page-layout4 .main > .slim h2,
.cb-page-layout4 .main > .slim .foot,
.cb-page-layout4 .main > .slim .part,
.cb-page-layout4 .main > .slim.grid table {
  margin-right: 4.12371134%;
  margin-left: 4.12371134%;
}
.cb-page-layout4 .main > .slim .part,
.cb-page-layout4 .main > .slim.grid table {
  width: 91.75257732%;
}
.cb-page-layout4 .main > .slim .tiny {
  width: 91.75257732%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 0.50761421%;
  margin-left: 0.50761421%;
}
.base .part,
.base > .grid table {
  margin-right: 0.50761421%;
  margin-left: 0.50761421%;
  width: 98.98477157%;
}
.base .tiny {
  width: 48.98477157%;
}
.base > .slim {
  width: 50%;
}
.base > .slim h2,
.base > .slim .foot,
.base > .slim .part,
.base > .slim.grid table {
  margin-right: 1.01522843%;
  margin-left: 1.01522843%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 97.96954315%;
}
.base > .slim .tiny {
  width: 47.96954315%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south h2,
.south .foot {
  margin-right: 2.02020202%;
  margin-left: 2.02020202%;
}
.south .part,
.south > .grid table {
  margin-right: 2.02020202%;
  margin-left: 2.02020202%;
  width: 95.95959596%;
}
.south .tiny {
  width: 20.95959596%;
}
.south > .slim {
  width: 25%;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 8.08080808%;
  margin-left: 8.08080808%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 83.83838384%;
}
.south > .slim .tiny {
  width: 83.83838384%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  width: 50.5%;
  margin-left: -0.5%;
}
.cb-page-layout2 .main {
  width: 25.25%;
}
.cb-page-layout4 .main {
  width: 101%;
  margin-left: -0.5%;
}
.side {
  float: right;
  width: 50.5%;
  margin-right: -0.5%;
}
.cb-page-layout2 .side {
  width: 75.75%;
}
.cb-page-layout2 .side div.wide {
  width: 66.66666667%;
}
.cb-page-layout2 .side div.slim {
  width: 33.33333333%;
}
.base {
  margin-top: 20px;
  margin-left: 0.75%;
  width: 98.5%;
}
.cb-page-layout1 div.seam.slim {
  float: right;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 50px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -450px;
  width: 900px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.desk--svk a {
  margin-top: 50px;
  margin-left: -50px;
  width: 48.33333333%;
  max-width: 580px;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #6f7071;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #6f7071;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #565757;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.11666667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #626364 #494a4a #494a4a #626364;
  border-radius: 2px;
  background-color: #6f7071;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(60, 61, 62, 0.75);
  line-height: 2.2em;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #626364 #494a4a #494a4a #626364;
  background-color: #777879;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #626364 #494a4a #494a4a #626364;
  background-color: #565757;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/*# sourceMappingURL=./screen-large.css.map */